import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "alternateCardBtn", "cardDiv", "currentCard" ]

  switchPaymentMethod() {
    const buttonElement = event.target;
    const alternateCardBtnElement = this.alternateCardBtnTarget;
    const btn_text = alternateCardBtnElement.firstChild.nodeValue;

    switch(btn_text) {
      case 'Use alternate card':
        this.cardDivTarget.style.display = 'block';
        this.currentCardTarget.style.display = 'none';
        buttonElement.innerHTML = 'Use card on file';
        break;
      case 'Use card on file':
        this.cardDivTarget.style.display = 'none';
        this.currentCardTarget.style.display = 'block';
        buttonElement.innerHTML = 'Use alternate card';
        break;
      default:
        // code block
    }
  }

}