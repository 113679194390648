import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["chBox", "btn", "switch", "trigger", "achievedOn"]

    connect() {
        this.myTableRow = this.chBoxTarget.closest("tr")
        this.myTableBody = this.myTableRow.closest("tbody")
        this.myTable = this.myTableBody.closest("table")
        this.initialVal = this.chBoxTarget.checked
        this.renewal = this.data.get("renewal")            
        if (this.initialVal) {
            this.allowbtn()
            this.tabChange()
        }
        if(this.renewal == 'true' && !this.chBoxTarget.checked)
        {
            this.chBoxTarget.disabled = true
        }
    }

    allowbtn(){
        this.initialVal = this.chBoxTarget.checked
        if (this.initialVal) {
            this.reset_checkboxes()
            this.disable_date_selects()
            this.reset_achievedOn()
            this.chBoxTarget.checked = this.initialVal
        }
        let sectATab = document.getElementById("section-a-tab")
        let sectBCTab = document.getElementById("section-b-c-tab")
        if( this.chBoxTarget.checked)
        {
            this.switchTarget.style.display = "block"
            sectBCTab.disabled = false
            if (this.renewal == 'true')
            {
                sectATab.disabled = true
            }
        }
        else
        {
            this.switchTarget.style.display = "none"
            sectBCTab.disabled = true                       
        }
    }

    hideButton(){
      let sectBCTab = document.getElementById("section-b-c-tab")
      this.switchTarget.style.display = "none"
      sectBCTab.disabled = true
    }

    tabChange(){
        let sectBCTab = document.getElementById("section-b-c-tab").click()
        window.scrollTo(0, 0);
    }

    reset_checkboxes() {
        var chx = this.myTableBody.querySelectorAll("tr td input[type='checkbox']" )
        chx.forEach((x) => {
            x.checked = false
        })

    }

    disable_date_selects() {
        var selects = this.myTableBody.querySelectorAll("select" )
        selects.forEach((x) => {
            x.disabled = true
            x.selectedIndex = ""
        })
    }

    reset_achievedOn() {
        var aons = this.myTableBody.querySelectorAll("input.achievedOn")
        aons.forEach((x) => {
            x.value = null
        })
    }



}