import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "badgeNumber" ]
  

  connect(){
    
  }

  validateBadgeNumber() {
    const badge_number = this.badgeNumberTarget.value
    this.badgeNumberLookup(badge_number)
  }

  badgeNumberLookup(badge_number) {
    fetch(`/mentees/mentor_badge_check?badge_number=${badge_number}`)
    .then( data => {
      return data.json(); 
    }).then( badgeNumberData => {
      let alert_div = document.createElement('div')
      alert_div.classList.add('alert', (badgeNumberData['result'] ? 'alert-success' : 'alert-danger'))
      alert_div.innerText = badgeNumberData['message']
      Array.from(this.badgeNumberTarget.parentNode.getElementsByClassName("alert")).forEach(elem => {
       elem.remove()
      })
      let that = this
      setTimeout(function(){that.badgeNumberTarget.parentNode.insertBefore(alert_div, that.badgeNumberTarget)}, 500)
      
    });
  }

  copyCourse(){    
    document.getElementById("chosen-course").innerHTML=this.data.get("course")
  }

}