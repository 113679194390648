import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "form", "paymentMethodSelect", "cardDiv", "bacsDiv", "chequeDiv" ]

  connect() {
    this.switchPaymentMethodDiv()
  }

  switchPaymentMethodDiv() {
    const paymentMethodElement = this.paymentMethodSelectTarget
    const selected_value = paymentMethodElement.options[paymentMethodElement.selectedIndex].value.toLowerCase();
    switch(selected_value) {
      case 'card':
        this.cardDivTarget.style.display = 'block';
        this.bacsDivTarget.style.display = 'none';
        this.chequeDivTarget.style.display = 'none';
        break;
      case 'bacs':
        this.cardDivTarget.style.display = 'none';
        this.bacsDivTarget.style.display = 'block';
        this.chequeDivTarget.style.display = 'none';
        break;
      case 'cheque':
        this.cardDivTarget.style.display = 'none';
        this.bacsDivTarget.style.display = 'none';
        this.chequeDivTarget.style.display = 'block';
        break;
      default:
        this.cardDivTarget.style.display = 'none';
        this.bacsDivTarget.style.display = 'none';
        this.chequeDivTarget.style.display = 'none';
        // code block
    }
  }

}