import { Controller } from "stimulus"

export default class extends Controller {
  //controller data-dependant-toggle-select-items will contain the values iin the select that should trigger the show
  static targets = ["toggle", "select","item"]

  connect(){
    console.log(this.toggleTarget);
    this.selectChanged();
  }

  selectChanged(e){

    let triggers = this.data.get("select-items").split();
    if(triggers.includes(this.selectTarget.value))
    {
      this.toggleTarget.classList.remove("d-none");
    }
    else
    {
      this.itemTarget.value="";
      this.toggleTarget.classList.add("d-none");
    }
  }
}
