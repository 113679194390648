import { Controller } from "stimulus"

export default class extends Controller {

    static targets= ["acceptCourse", "courseReject", "courseTick", "acceptPlan", "planReject", "planTick", "courseReason", "planReason"]

    connect () {
        this.menteesSubmit = document.getElementById("mentees-submit")
        if (this.hasAcceptPlanTarget) 
        {
            if(this.acceptPlanTarget.value == 'false' )
            {
                this.rejectPlan()
            }
        }
        if(this.hasAcceptCourseTarget)
        {
            if (this.acceptCourseTarget.value == 'false' )
            {
                this.rejectCourse()
            } 
        }
               
    }

    approveCourse(){        
        this.acceptCourseTarget.value = true
        this.menteesSubmit.disabled = false
        this.courseTickTarget.style.display="inline-block"
        this.courseRejectTarget.classList.add("hide","fade")
        this.courseReasonTarget.value=""       
    }

    rejectCourse(){        
        this.acceptCourseTarget.value = false
        this.menteesSubmit.disabled = false
        this.courseTickTarget.style.display="none"
        this.courseRejectTarget.classList.remove("hide", "fade")
    }

    approvePlan(){        
        this.acceptPlanTarget.value = true
        this.menteesSubmit.disabled = false
        this.planTickTarget.style.display="inline-block"
        this.planRejectTarget.classList.add("hide","fade")
        this.planReasonTarget.value=""
    }

    rejectPlan(){        
        this.acceptPlanTarget.value = false
        this.menteesSubmit.disabled = false
        this.planTickTarget.style.display="none"
        this.planRejectTarget.classList.remove("hide", "fade")
    }


}