import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "chBox", "daySelect", "monthSelect", "yearSelect", "achievedOn", "switch" ]

  connect() {
    this.myTableRow = this.chBoxTarget.closest("tr")
    this.myTableBody = this.myTableRow.closest("tbody")
    this.myTable = this.myTableBody.closest("table")
    this.initialVal = this.chBoxTarget.checked
    this.elGrp = this.myTable.dataset.elGroup    
    this.setState()
    this.toggle_trainee_info()
  }

  setState() {
     
      var selectsInRow = this.myTableRow.querySelectorAll("select")
      selectsInRow.forEach(selectElement => {
          if (this.initialVal) {
              selectElement.disabled = false
          } else {
              selectElement.disabled = true
              selectElement.selectedIndex = ""
              this.achievedOnTarget.value = null
          }

      });      
      this.chBoxTarget.checked = this.initialVal
      
  }

  toggle() {
      this.initialVal = this.chBoxTarget.checked
      this.hide_section_switch()
      this.reset_checkboxes()
      this.disable_date_selects()
      this.reset_achievedOn()
      this.toggle_trainee_info()
      this.setState()
      if(this.myTable.dataset.elGroup == "MRA" && this.initialVal)
      {
        let closit = new Event("closit")
        document.dispatchEvent(closit)
      }

  }

  hide_section_switch() {
      let sectBCTab = document.getElementById("section-b-c-tab")
      let nextSectBtn = document.getElementById('nextSectionsButton')
      if ( nextSectBtn) {
          nextSectBtn.classList.add("hide")
          sectBCTab.disabled = true
      }

  }

  reset_checkboxes() {         

      if(/S\d/.test(this.myTable.dataset.elGroup))
      {        
        let tables = (document.querySelectorAll("table[data-el-group]"))
        tables.forEach((t) => {
            if(t.dataset.elGroup == this.myTable.dataset.elGroup)
                {}
            else
                {
                  var tableChx = t.querySelectorAll("tbody tr td input[type='checkbox']" )
                  tableChx.forEach((chx)=>{
                    chx.checked=false
                  })
                }
        })
      }
      else
      {
        var chxB = this.myTableBody.querySelectorAll("tr td input[type='checkbox']" )
      
        chxB.forEach((x) => {
            x.checked = false
        })
      }

    
  }

  disable_date_selects() {      
      
      if(/S\d/.test(this.myTable.dataset.elGroup))
      {
        
        let tables = (document.querySelectorAll("table[data-el-group]"))
        tables.forEach((t) => {
           
            if(t.dataset.elGroup == this.myTable.dataset.elGroup)
                {}
            else
                {
                  var tableSelects = t.querySelectorAll("tbody select" )
                  tableSelects.forEach((sel)=>{
                    sel.disabled = true
                    sel.selectedIndex = null
                  })
                }
        })
      }
      else
      {
        
        var selects = this.myTableBody.querySelectorAll("select" )
        selects.forEach((x) => {
          x.disabled = true
          x.selectedIndex = ""
        })
      }
  }

  reset_achievedOn() {
    
    
      //handle mandatory group - similarly for the other 2 methods
      if(/S\d/.test(this.myTable.dataset.elGroup))
      {
        let tables = (document.querySelectorAll("table[data-el-group]"))
        tables.forEach((t) => {
            if(t.dataset.elGroup == this.elGrp)
                {}
            else
                {
                  var taons = t.querySelectorAll("tbody input.achievedOn" )
                  taons.forEach((taon)=>{
                    taon.value = null
                  })
                }
        })
      }
      else
      {
        var aons = this.myTableBody.querySelectorAll("input.achievedOn")
            aons.forEach((x) => {
                x.value = null
            })
      }  

    }

    updateAchievedOn() {
        this.achievedOnTarget.value = this.daySelectTarget.value + "/" + this.monthSelectTarget.value + "/" + this.yearSelectTarget.value
    }

    toggle_trainee_info() {
        if (this.initialVal) {
            
            switch(this.elGrp){
                case "TRA":  case "TSL":               
                    var regx = new RegExp("course")
                    if (regx.test(this.chBoxTarget.dataset.description)  )
                    {
                        this.showCourse()
                    }
                    else
                    {
                        this.showPlan()
                    }
                    break;
            }
        } else {
            switch(this.elGrp){
                case "TRA": case "TSL":
                    var regx = new RegExp("course")
                    if (regx.test(this.chBoxTarget.dataset.description)  )
                    {
                        this.hideCourse()
                    }
                    else
                    {
                        this.hidePlan()
                    }
                    break;
            }
        }

    }

  toggle2(){

        this.chBoxTarget
        var tbod = this.chBoxTarget.closest("tr").closest("tbody")
        var tabl = tbod.closest("table")
        var elGrp = tabl.dataset.elGroup
        if (this.chBoxTarget.checked)
        {
            var chx = tbod.querySelectorAll("tr td input[type='checkbox']" )


            var slct=tbod.querySelectorAll("tr select")
            slct.forEach((s)=>{
                s.disabled = true
                s.value=""
            })
            this.chBoxTarget.checked = true
            this.datesTargets.forEach (
                (d)=> {
                    d.disabled=false
                }
            )

            switch(this.elGrp){
                case "TSL":
                    var regx = new RegExp("course")
                    if (regx.test(this.chBoxTarget.dataset.description)  )
                    {
                        this.showCourse()
                    }
                    else
                    {
                        this.showPlan()
                    }
                    break;
            }
        }
        else
        {
            this.datesTargets.forEach (
                (d)=> {
                    d.disabled=true
                    d.value=""
                }
            )
            if(elGrp=="TSL")
            {
                document.getElementById("coursepicker").style.display="none"
                document.getElementById("coursetext").style.display="none"
                document.getElementById("devplan").style.display="none"
            }
        }

  }

  showPlan(){   
    document.getElementById("coursepicker").style.display="none"
    document.getElementById("coursetext").style.display="none"
    document.getElementById("devplan").style.display="block"
  }

  showCourse(){    
    document.getElementById("coursepicker").style.display="block"
    document.getElementById("coursetext").style.display="block"
    document.getElementById("devplan").style.display="none"   
  }

  hidePlan() {
    document.getElementById("devplan").style.display="none"
  }

  hideCourse() {
      document.getElementById("coursepicker").style.display="none"
      document.getElementById("coursetext").style.display="none"

  }
}