import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "modal" ]

  

  showModal() {    
   this.mod = new bootstrap.Modal(this.modalTarget, {
     backdrop: true
   })
   this.mod.show()
  }


}