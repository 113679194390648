import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["postcode", "postcodesFormGroup", "country", "popup", "ul", "getAddressBtn", "address1", "address2", "address3", "town", "county"]

  connect() {
    this.hideAddressLookupIfIreland()
    this.hidePostcodeIfIreland()
  }

  loadPostcodeData() {
    const postcode = this.postcodeTarget.value
    const country = this.countryTarget.value

    fetch(`${this.data.get("url")}?country=${country}&postcode=${postcode}`)
      .then(data => {
        return data.json();
      }).then(addressData => {
        addressData.forEach((addressObject, index) => {
          var address = JSON.stringify(addressObject)
          sessionStorage.setItem(addressObject['udprn'], address);
        });
        //var addresses = JSON.stringify(addressData)
        //sessionStorage.setItem('addressData', addresses);
        this.setAddressPopup(addressData)
      });
  }

  setAddressPopup(addressData) {
    //var addresses = JSON.parse(sessionStorage.getItem('addressData'));
    var addresses = addressData;
   

    this.showPopup()
    var listItems = this.ulTarget.querySelectorAll("li")
    listItems.forEach((li) => {
      li.remove()
    })
    addresses.forEach((addressObject, index) => {
      var value = addressObject['building_number'] + ' ' + addressObject['building_name'] + ' ' + addressObject['sub_building_name'] + ' ' + addressObject['thoroughfare']
      
      var udprn = addressObject['udprn']
      var li = document.createElement("li")
      li.dataset.udprn = udprn
      li.dataset.action = "click->postcodes#setAddressData"
      li.appendChild(document.createTextNode(value))
      this.ulTarget.appendChild(li)      
    });
    var lastLi = document.createElement("li")
    lastLi.dataset.action = "click->postcodes#hidePopup"
    lastLi.appendChild(document.createTextNode("Address not listed? Enter it manually"))
    this.ulTarget.appendChild(lastLi)
  }

  showPopup(){
    this.popupTarget.classList.add("show")
  }

  hidePopup(){
    this.popupTarget.classList.remove("show")
  }

  setAddressData(e) {    
    const selected_udprn = e.target.dataset.udprn
    var addressData = JSON.parse(sessionStorage.getItem(selected_udprn));
    this.address1Target.value = addressData.line_1
    this.address2Target.value = addressData.line_2
    this.address3Target.value = addressData.line_3
    this.townTarget.value = addressData.post_town
    this.countyTarget.value = addressData.county
    this.popupTarget.classList.remove("show")
  }

  hideAddressLookupIfIreland() {
    const country_value = this.countryTarget.value
    if (country_value === 'Ireland') {
      
      this.getAddressBtnTarget.style.display = "none"
    }
    else {
     
      this.getAddressBtnTarget.style.display = "block"
    }
  }

  hidePostcodeIfIreland() {
    const country_value = this.countryTarget.value
    if (country_value === 'Ireland') {
      this.postcodesFormGroupTarget.style.display = "none"
    }
    else {
      this.postcodesFormGroupTarget.style.display = "block"
    }
  }
}