import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hide","trigger"  ]

  connect() {
    this.toggle()
  }

  toggle(){
    if(this.triggerTarget.checked)    {
        this.hideTarget.classList.add("show")
    }
    else
    {
        this.hideTarget.classList.remove("show")
    }
  }
}